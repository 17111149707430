// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    id: string
    navigation: any;
    // Customizable Area End
}

export type infoType = 'auditType' | 'auditorName' | 'accountName' | 'regionName' | 'locationName' | 'departmentName'

export interface InfoData {
    auditType: string, 
    auditorName: string, 
    accountName: string, 
    regionName: string, 
    locationName: string, 
    departmentName: string
}

interface S {
    // Customizable Area Start
    isSuccessDialog: boolean
    listFormName: Array<any>
    listAuditorName: Array<any>
    listAccount: Array<any>
    listRegionName: Array<any>
    listLocationName: Array<any>
    listDepartmentName: Array<any>
    infoData: InfoData2
    disableButton: boolean, 
    isLoading: boolean,
    error: boolean
    userRole: {id: string, role: string}
    isPageLoading: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    navigation: any;
    // Customizable Area End
}

// Customizable Area Start
type NumerOrString = number | string | null
type Address = {id: string, name: string} 
export interface UserType {
    id: number,
    first_name: string | null,
    last_name: string | null,
    full_phone_number: string | null,
    country_code: string | null,
    phone_number: string | null,
    email: string | null,
    activated: boolean,
    device_id: NumerOrString,
    unique_auth_id: string | null,
    password_digest: string | null,
    created_at: string | null,
    updated_at: string | null,
    user_name: string | null,
    platform: string | null,
    user_type: string | null,
    app_language_id: NumerOrString,
    last_visit_at: string | null,
    is_blacklisted: boolean,
    suspend_until: string | null,
    status: string | null,
    role_id: NumerOrString,
    full_name: string | null,
    gender: string | null,
    date_of_birth: string | null,
    age: string | null,
    access_type: string,
    team_id: string | null,
    account_name: string | null,
    region_name: string | null,
    location_name: string | null,
    department_name: string | null
}

export interface InfoData2 {
    auditType: string, 
    auditorName: UserType | null | string, 
    accountName: UserType | null | string, 
    regionName: Address | string, 
    locationName: Address | "", 
    departmentName: Address | ""
}
// Customizable Area End

export default class AuditAllocatorController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    createAuditAllocatorId: string = ""
    getListAuditorId: string = ""
    getListAccountId: string = ""
    getListRegionId: string = ""
    getListLocationid: string = ""
    getListDepartmentId: string = ""
    getUserRoleId: string = ""
    getFormNameId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            // Customizable Area End
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start


        this.state = {
            isSuccessDialog: false,
            isLoading: false,
            listFormName: [],
            listAuditorName: [],
            listAccount: [],
            listRegionName: [],
            listLocationName: [],
            listDepartmentName: [],
            infoData: {
                auditType: "", 
                auditorName: "", 
                accountName: "", 
                regionName: "", 
                locationName: "", 
                departmentName: ""
            },
            disableButton: true,
            error: false,
            userRole: {
                id: "",
                role: ""
            },
            isPageLoading: true
        };
        // Customizable Area End
    }
    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        await this.getUserRole()
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
              );
        
              let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
              );  

            if (apiCallId === this.createAuditAllocatorId) {
                if (responseJson.data) {
                    this.setState({
                        isSuccessDialog: true, disableButton: true, isLoading: false,
                        infoData: {
                            auditType: "",
                            auditorName: "",
                            accountName: "",
                            regionName: "",
                            locationName: "",
                            departmentName: ""
                        }
                    });
                    setTimeout(() => { this.setState({ isSuccessDialog: false }) }, 2000)
                } else {
                    this.handleResponseErr(responseJson.errors)
                }
            }
            else if(responseJson?.errors) {
                this.handleResponseErr(responseJson.errors)
            } else {
                this.handleGetDataSuccess(apiCallId, responseJson)
            }
           
        }
    }

    handleGetDataSuccess = async (apiCallId: string, responseJson: any) => {
        if(apiCallId === this.getUserRoleId) {
            this.setState({userRole: {...responseJson.meta}, isPageLoading: false})
            if(responseJson.meta.role === "All Access User") {
                await this.getDropdownData()
            }
        }
        if (apiCallId === this.getFormNameId) {
            const listFormName = responseJson.data.map((formName: any) => {return formName.name})
            this.setState({ listFormName: listFormName })
        }
        if (apiCallId === this.getListAuditorId) {
            this.setState({ listAuditorName: responseJson.data })
        }
        if (apiCallId === this.getListAccountId) {
            this.setState({ listAccount: responseJson })
        }
        if (apiCallId === this.getListRegionId) {
            this.setState({ listRegionName: [...responseJson[0].regions] })
        }
        if (apiCallId === this.getListLocationid) {
            const locationData = responseJson.regions[0].locations.map((locItem: any) =>{ return {id: locItem.location_id, name: locItem.location_name}})

            this.setState({ listLocationName: [...locationData] })
        }
        if (apiCallId === this.getListDepartmentId) {
            const departmentData = responseJson.locations[0].departments.map((depItem: any) =>
                { return {id: depItem.department_id, name: depItem.department_name}})

            this.setState({ listDepartmentName: [...departmentData]})
        }
    }

    handleResponseErr = (errors: Array<any>) => {
        const errorList: {[key: string] : string} = {};
        Array.isArray(errors) && errors.forEach((error: Object) => {
            errorList[Object.keys(error)[0]] = Object.values(error)[0]
        });
        if(errorList['token']) {
            removeStorageData("authToken"); 
            this.goLogin()
        }
        else {
            this.setState({
                isSuccessDialog: true, isLoading: false,
                error: true, 
                disableButton: false
            });
            setTimeout(()=>{this.setState({isSuccessDialog: false})}, 2000)
        }
    }

    
    goLogin = async () => {
        await setStorageData("name", "AuditAllocator")
        this.props.navigation.navigate("EmailAccountLoginBlock")
    }

    checkEmptyData = () => {
        const {auditType, auditorName, regionName, locationName, accountName} = this.state.infoData
        return auditType.length === 0 || !auditorName || Object.keys(regionName ?? {}).length === 0 || Object.keys(locationName ?? {}).length === 0
        || !accountName
    }

    formatData = (dataJson: Array<string>) => {
        return dataJson.filter((item: string) => item && item.length > 0)
    }

    getDropdownData = async () => {
        this.getFormNameId = await this.apiCall({
            method: configJSON.getApiMethod, 
            endpoint: configJSON.getFormNameEndpoint
        })
        this.getListAuditorId = await this.apiCall({
            method: configJSON.getApiMethod, 
            endpoint: `${configJSON.filterAccountEndpoint}?attribute_value=assigned_to`
        })
        this.getListAccountId = await this.apiCall({
            method: configJSON.getApiMethod, 
            endpoint: configJSON.getAccountNameEndpoint
        })
        
    }

    getListRegion = async (userId: string) => {
        this.getListRegionId = await this.apiCall({
            method: configJSON.getApiMethod, 
            endpoint: `${configJSON.getUserRegionEndpoint}?user_ids[]=${userId}`
        })
    }

    getListLocation = async (regionId: string) => {
        this.getListLocationid = await this.apiCall({
            method: configJSON.getApiMethod, 
            endpoint: `${configJSON.getListLocationEndpoint}?region_ids[]=${regionId}`
        })
    }

    getListDepartment = async (locationId: string) => {
        this.getListDepartmentId = await this.apiCall({
            method: configJSON.getApiMethod, 
            endpoint: `${configJSON.getListDepartmentEndpoint}?location_ids[]=${locationId}`
        })
    }

    apiCall = async (reqData: {method: string, endpoint: string, body?: {}}) => {
        const token = await getStorageData('authToken')
        const {endpoint, method, body} = reqData
        const reqMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        reqMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );

        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": 'application/json',
                token
            })
        );
        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        runEngine.sendMessage(reqMsg.id, reqMsg);
        return reqMsg.messageId
    }

    getUserRole = async () => {
        this.getUserRoleId = await this.apiCall({
            method: configJSON.getApiMethod, 
            endpoint: configJSON.getUserRoleEndpoint
        })
    }

    onClickAssignAudit = async () => {
        this.setState({isLoading: true})
        const auditor = this.state.infoData.auditorName as UserType;
        const account = this.state.infoData.accountName as UserType
        const location = this.state.infoData.locationName as Address
        const department = this.state.infoData.departmentName as Address
        const region = this.state.infoData.regionName as Address

        const body = {
            audit: {
                form_name: this.state.infoData.auditType,
                auditor_id: auditor.id,
                user_id: account.id,
                region_id: region.id,
                location_detail_id: location.id,
                department_id: department.id
            }
        }
        this.createAuditAllocatorId = await this.apiCall({
            method: configJSON.postApiMethod, 
            endpoint: configJSON.auditAllocatorEndpoint, 
            body
          })
    }

    toggleSuccessDialog = () => {
        this.setState({isSuccessDialog: !this.state.isSuccessDialog})
    }

    onChangeInfoData = async (key: infoType, value: any) => {
        const { infoData } = this.state;
        infoData[key] = value
        if (key === "accountName") {
            if (value) {
                infoData.regionName = ""
                await this.getListRegion(value.id)
            }
            else {
                infoData.regionName = ""
                infoData.locationName = ""
                infoData.departmentName = ""
            }

        }
        if (key === "regionName") {
            infoData.locationName = ""
            infoData.departmentName = ""
            await this.getListLocation(value.id)
        }
        if (key === "locationName") {
            infoData.departmentName = ""
            await this.getListDepartment(value.id)
        }
        this.setState({ infoData: { ...infoData }, disableButton: this.checkEmptyData(), error: false })
    }
    
    // Customizable Area End
}
// Customizable Area End
